import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../sectionTitle/SectionTitle';
import ScrollAnimation from 'react-animate-on-scroll';
import { Element } from 'react-scroll';

const items = [
	{
		id: 1,
		title: 'Applied AI Solutions',
		info: 'Unlocking AI solutions tailored to your business needs, from customized developments to our innovative in-house offerings.',
		numberOfCourses: '16 weeks',
		modeOfCourses: 'Anywhere',
		category: 'Data Science',
		image: 'applied-ai-solutions_v2.webp',
		link: '/solutions/applied-ai-solutions',
		form: 'wil'
	},
	{
		id: 2,
		title: 'Industry Aligned Upskilling',
		info: 'Join a cohort and revolutionize your learning experience where collaboration, community and personalization meet.',
		numberOfCourses: '12 weeks',
		modeOfCourses: 'Anywhere',
		category: 'Data Science, ML and AI',
		image: 'industry-aligned-upskilling_v2.webp',
		link: '/solutions/industry-aligned-upskilling',
		form: 'wil'
	},
	// {
	// 	id: 3,
	// 	title: 'Clean Energy Solutions',
	// 	info: 'The goal of this project is to create a clean energy management system in smart grids using reinforcement learning.',
	// 	numberOfCourses: '12 weeks',
	// 	modeOfCourses: 'Anywhere',
	// 	category: 'Ecommerce',
	// 	image: 'clean-energy-solutions.jpg',
	// 	link: '/solutions/clean-energy-solutions',
	// 	form: 'wil'
	// },
	// {
	// 	id: 4,
	// 	title: 'Supply Chain Optimization',
	// 	info: 'The goal of this project is to create an efficient supply chain optimization system using Python libraries such as NumPy and Pandas.',
	// 	numberOfCourses: '12 weeks',
	// 	modeOfCourses: 'Anywhere',
	// 	category: 'Business Intelligence',
	// 	image: 'banner-course.jpg',
	// 	link: '/solutions/supply-chain-optimization',
	// 	form: 'wil'
	// },
	// {
	// 	id: 5,
	// 	title: 'Sustainable Cities & Communities',
	// 	info: 'Develop AI-powered energy management systems and smart grid solutions to optimize energy consumption, reduce carbon emissions, and enhance energy efficiency in urban environments.',
	// 	numberOfCourses: '12 weeks',
	// 	modeOfCourses: 'Anywhere',
	// 	category: 'Business Intelligence',
	// 	image: 'banner-course.jpg',
	// 	link: '/solutions/sustainable-cities-and-communities',
	// 	form: 'wil'
	// },
	// {
	// 	id: 6,
	// 	title: 'Jobs & Automation',
	// 	info: 'Develop AI-driven platforms and tools to match job seekers with suitable employment opportunities based on their skills, experience, and preferences.',
	// 	numberOfCourses: '12 weeks',
	// 	modeOfCourses: 'Anywhere',
	// 	category: 'Business Intelligence',
	// 	image: 'banner-course.jpg',
	// 	link: '/solutions/jobs-and-automation',
	// 	form: 'wil'
	// },
]

const HomeOneSolutions = () => {
	return (
		<>
			<Element name="solutions" className="edu-about-area about-style-1 edu-section-gap home-one-cat" id="ProgramshowCase">
				<div className="container">
					<div className="row g-5">
						<div className="col-12">
							<SectionTitle
								classes = "text-center"
								slogan = "What We Do"
								title = "Our Solutions"
							/>
						</div>
            { items.map( ( data , i ) => (
              <ScrollAnimation 
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                className="col-lg-6 col-md-6 col-sm-6 col-12"
                animateOnce={ true }
                key={ i }
              >
                <div className="research-projects research-service-card service-card-1 radius-small">
                  <div className="inner">
                  <div className="thumbnail">
                    <a href={ data.link }>
                      <img src={`${process.env.PUBLIC_URL}/images/solutions/${ data.image }`} alt="Category Thumb" loading="lazy" />
                    </a>
                  </div>
                    <div className="content program-info">
                      {/* <div className="program-info-content d-flex justify-content-between">
                        <span className="course-total"><i className="icon-time-line"></i> { data.numberOfCourses }</span>
                        <span className="course-total"><i className="icon-presentation"></i> { data.category }</span>
                      </div> */}
                      <h6 className="title mt-3"><a href={ data.link }>{ data.title }</a></h6>
                      <p className="mb-0">{ data.info }</p>
                      {/* <p className="description">{ data.info.slice(0, 340) + "..." }</p> */}
                      {/* <div className="d-flex justify-content-between flex-wrap mt-3">
                        {data.form ==="skillsboost" ? <a className="edu-btn btn-secondary mt-3" href="https://form.jotform.com/m2mtech/skillsboost-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a> :
                        data.form === "aiminds" ? <a className="edu-btn btn-secondary mt-3" href="https://form.jotform.com/m2mtech/aiminds">Register Now<i className="icon-arrow-right-line-right"></i></a> :
                        data.form === "wil" ? <a className="edu-btn btn-secondary mt-3" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a> :
                        data.form === "datatalent" ? <a className="edu-btn btn-secondary mt-3" href="https://form.jotform.com/m2mtech/cohort-sign-up">Jobseekers login<i className="icon-arrow-right-line-right"></i></a> :
                        <span></span>}
                        <Link className="edu-btn btn-bg-alt mt-3" to={ data.link }>Find Out More<i className="icon-arrow-right-line-right"></i></Link>
                      </div> */}
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            ) ) }
            
            {/* <div className="row text-center mt--60">
              <div className="col-lg-12">
                <ScrollAnimation 
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="load-more-btn"
                  animateOnce={ true }
                >
                  <Link className="edu-btn" to="/careers/work-integrated-learning">All Projects<i
                  className="icon-arrow-right-line-right"></i></Link>
                </ScrollAnimation>
              </div>
            </div> */}
            <div className="inner">
              <div className="shape shape-6 about-parallax-2 d-xl-block d-none">
                <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-12.png`} alt="Shape Thumb" loading="lazy" />
              </div>
            </div>
					</div>          
				</div>
			</Element>
		</>
	)
}

export default HomeOneSolutions;