import React from 'react';
import { Element } from 'react-scroll';

const items = [
	{
		"id": 1,
		"image": "Terramera_Logo.png",
		"title": 'Cult Fit',
		"website": 'https://www.cult.fit/',
		"description": "Cult Fit, previously known as cure.fit or Curefit, is a comprehensive health and wellness company that provides both digital and in-person services spanning fitness, nutrition, and mental well-being. Dedicated to making fitness enjoyable and accessible, Cult Fit redefines workouts through a variety of instructor-led group fitness classes. The platform not only ensures enjoyable exercise routines but also promotes a daily diet that is both nutritious and delicious. Additionally, it simplifies mental fitness with offerings like yoga and meditation, and streamlines medical and lifestyle care for a hassle-free experience.",
		"link": "/work-integrated-learning/cult-fit",
		"externalLink": "https://www.cult.fit/careers",
	},
	{
		"id": 2,
		"image": "Terramera_Logo.png",
		"title": 'PharmEasy',
		"website": 'https://pharmeasy.in/#',
		"description": "PharmEasy is a consumer healthcare application that offers on-demand, home-delivered access to a diverse array of prescription medications, over-the-counter pharmaceuticals, and various other consumer healthcare products. In addition to this, the platform facilitates comprehensive diagnostic test services and teleconsultations, catering to a spectrum of healthcare needs. PharmEasy's user-centric approach not only prioritizes convenience but also emphasizes the pivotal significance of healthcare accessibility, ensuring that individuals can easily and efficiently address their health requirements.",
		"link": "/work-integrated-learning/pharmeasy",
		"externalLink": "https://myhr.darwinbox.in/ms/candidate/careers",
	},
	{
		"id": 3,
		"image": "Terramera_Logo.png",
		"title": 'Practo',
		"website": 'https://www.practo.com/',
		"description": "Practo is dedicated to the mission of ensuring affordable and accessible quality healthcare for over a billion Indians. Their commitment revolves around empowering users with precise, comprehensive, and carefully curated information and care, empowering them to make informed and improved healthcare decisions. By combining technology with a dedication to customer service, Practo aims to bridge gaps in healthcare access, providing individuals with the means to take control of their health journey.",
		"link": "/work-integrated-learning/practo",
		"externalLink": "https://practo.app.param.ai/jobs/",
	},
	{
		"id": 4,
		"image": "Terramera_Logo.png",
		"title": 'DocPlexus',
		"website": 'https://www.docplexus.com/',
		"description": "DocPlexus started with the belief that a well-informed doctor is positioned to make better clinical decisions. DocPlexus facilitates round-the-clock exchange of dialogues with other members and experts, at any time from anywhere, and offers knowledge centers, treatment guidelines, certified educational resources, latest medical news, and industry updates, every single day. Docplexus is committed to creating an environment where healthcare professionals can engage, learn, and stay updated, ultimately enhancing the quality of medical decision-making and patient care.",
		"link": "/work-integrated-learning/docplexus",
		"externalLink": "https://www.docplexus.com/pub/career",
	},
	{
		"id": 5,
		"image": "Terramera_Logo.png",
		"title": 'Ekincare',
		"website": 'https://www.ekincare.com/',
		"description": "Ekincare embarked on its journey as a medical records platform with a mission to aid non-resident Indians in monitoring the health of their family members back home. Rooted in the commitment to bring order and standardization to India's diverse healthcare landscape, Ekincare leverages cutting-edge technologies such as AI, deep analytics, and patented solutions. Recognizing a more intricate challenge in the corporate health benefits and group insurance sector, Ekincare strategically pivoted to become an exclusive B2B provider, solidifying its position as a leader in the industry.",
		"link": "/work-integrated-learning/ekincare",
		"externalLink": "https://ekincare.keka.com/careers/",
	},
];

const HealthTechEmployers = () => {
	return (
		<Element
			name="what-we-do-one"
			className="edu-about-area about-style-1 edu-section-gap bg-color-white"
		>
			<div className="container">
				<div className="col-lg-12 pre-section-title text-center">
					<span className="color-primary pretitle">Employer Profiles</span>
					<h3 className="title">HealthTech Employers</h3>
				</div>
				<div className="row g-5 align-items-center">
					<div className="col-lg-12">
						<div className="inner">
							<p className="employer-description">
								Transforming healthcare through innovative health tech solutions, fostering wellness, accessibility, and personalized care for a healthier and brighter future. These are the Canadian based employers that are providing jobs in HealthTech industry:
							</p>

							<div className="shape shape-6 about-parallax-2 d-xl-block d-none">
								<img src={`${process.env.PUBLIC_URL}/images/shapes/shape-12.png`} alt="Shape Thumb" loading="lazy" />
							</div>
						</div>
					</div>
					<div className="col-lg-12">
						<div className="container">
							{ items && items.length > 0 &&
								<div className="row g-5 employer-list-desktop">
									{ items.map( ( data , i ) => (
										<div key={i} className="col-md-4 employer-wrapper">
											<div className="employer-inner service-card-1">
												<div className="employer-content">
													<h5 className="feature-title"><a href={ data.website } alt="website">{ data.title }</a></h5>
													<p className="feature-description">{ data.description }</p>
												</div>
												<div className="d-flex justify-content-center flex-wrap">
													<a className="edu-btn btn-secondary mt-3" href={ data.externalLink }>Current Jobs<i className="icon-Double-arrow"></i></a>
												</div>
											</div>
										</div>
									) ) }
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</Element>
	)
}

export default HealthTechEmployers;