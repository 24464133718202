import React from 'react';
import { Element } from 'react-scroll';
import SkillsboostSectionTitle from './SkillsboostSectionTitle';

const SkillsboostHowToGetStarted = ({
  setShowSkillsboostSignUpForm
}) => {
  return (
    <>
      <Element
        name="HowToGetStarted"
        className="about-style-1 edu-section-gap service-wrapper-1"
      >
        <div className="edu-about-area">
          <div className="container about-feature-list eduvibe-animated-shape">
            <div className="col-lg-12">
              <SkillsboostSectionTitle
                classes = "text-center"
                // slogan = "Eligibility"
                title = "How To Get Started"
              />
            </div>
            <div className="container">
              <div className="row g-5 align-items-center mt--40">                
                <div className="col-lg-7 order-2 order-lg-1">
                  <div className="inner">
                    <h5 className="text-left mb-0 mt-4">Enroll</h5>
                    <div className="our-feature mt-4">
                      <div className="icon">
                        <i className="icon-book-mark-fill"></i>
                      </div>
                      <div className="feature-content">
                        <p className="feature-description">Sign up for the SkillsBoost program to embark on your journey to becoming a certified Cloud AI professional.</p>
                      </div>
                    </div>
                    <h5 className="text-left mb-0 mt-4">Learn and Practice</h5>
                    <div className="our-feature mt-4">
                      <div className="icon">
                        <i className="icon-book-mark-fill"></i>
                      </div>
                      <div className="feature-content">
                        <p className="feature-description">Dive into our comprehensive courses and make the most of your Cloud AI resource subscription to gain practical experience.</p>
                      </div>
                    </div>
                    <h5 className="text-left mb-0 mt-4">Certify</h5>
                    <div className="our-feature mt-4">
                      <div className="icon">
                        <i className="icon-book-mark-fill"></i>
                      </div>
                      <div className="feature-content">
                        <p className="feature-description">Prepare for the certification exam and take the next step in your career by achieving Cloud AI certification.</p>
                      </div>
                    </div>
                    <h5 className="text-left mb-0 mt-4">Explore Opportunities</h5>
                    <div className="our-feature mt-4">
                      <div className="icon">
                        <i className="icon-book-mark-fill"></i>
                      </div>
                      <div className="feature-content">
                        <p className="feature-description">Stay connected with us for updates on paid WIL opportunities tailored to your skill level and career goals.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 order-1 order-lg-2">
                    <img className="image-1" src={`${process.env.PUBLIC_URL}/images/skillsboost/undraw_fill_form_re_cwyf.svg`} alt="decoration" loading="lazy" />
                </div>
              </div>
            </div>
            <p className="skillsboost-cta-note mt--40">Unlock a world of opportunities with SkillsBoost. Enroll now and elevate your career in Information and Communication Technology!</p>
            <div className="read-more-btn mt--40">
              <a className="edu-btn" href="https://form.jotform.com/m2mtech/courses-interested-form">Enroll Now</a>
            </div>
            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
              <div className="shape-image shape-image-1">
                <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-01.png`} alt="Shape Thumb" loading="lazy" />
              </div>
              <div className="shape-image shape-image-2">
                <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-02.png`} alt="Shape Thumb" loading="lazy" />
              </div>
              <div className="shape-image shape-image-3">
                <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-01.png`} alt="Shape Thumb" loading="lazy" />
              </div>
              <div className="shape-image shape-image-4">
                <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-08.png`} alt="Shape Thumb" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default SkillsboostHowToGetStarted;