import React from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollTo } from 'react-scroll';

const AppliedAISolutionsBanner = ({
  scrollIntoViewWithOffset
}) => {

  return (
  <>
    <div className="applied-ai-solutions-banner banner-style-1 bg-image height-640 d-flex align-items-center banner-two">
      <div className="container eduvibe-animated-shape">
        <div className="row g-5 row--40 align-items-center">
          <div className="order-1 order-xl-1 col-lg-12 col-xl-6 banner-left-content">
            <div className="inner">
              <div className="content">
                {/* <h1 className="title">Revolutionizing Industries with Innovative AI Solutions</h1> */}
                <h1 className="title">Applied AI Solutions</h1>
                <p className="description">Revolutionizing Industries with Innovative AI solutions and comprehensive AI expertise spanning Predictive Analytics, Generative AI, Anomaly Detection, Sentiment Analysis, Recommendation Systems, Natural Language Processing, Knowledge Mining and beyond.</p>
                <div className="read-more-btn banner-btns">
                  <ScrollTo
                    className="edu-btn"
                    to="our-applied-ai-solutions"
                    spy={true}
                    smooth={true}
                    duration={200}
                  >
                    View Our Applied AI Solutions<i className="icon-arrow-right-line-right d-none d-md-inline-block"></i>
                  </ScrollTo>
                  <Link className="edu-btn btn-secondary" to="/contact-us">Contact Us<i className="icon-arrow-right-line-right d-none d-md-inline-block"></i></Link>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-xl-2 order-xl-3"></div> */}
          <div className="order-2 order-xl-2 col-lg-12 col-xl-6 d-none d-md-flex banner-right-content">
            {/* <img src={`${process.env.PUBLIC_URL}/images/banner/banner-01/ai_solutions.png`} width="400px" alt="illustration" /> */}
            <div>
              <ul className='circle-container'>
                <li
                  onClick={() => scrollIntoViewWithOffset("FourSight")}
                >
                  <img src={`${process.env.PUBLIC_URL}/images/banner/banner-01/predictive_analytics.png`} alt="illustration" />
                </li>
                <li
                  onClick={() => scrollIntoViewWithOffset("PostAI")}
                >
                  <img src={`${process.env.PUBLIC_URL}/images/banner/banner-01/generative_ai.png`} alt="illustration" />
                </li>
                <li
                  onClick={() => scrollIntoViewWithOffset("RealEstateOutlier")}
                >
                  <img src={`${process.env.PUBLIC_URL}/images/banner/banner-01/anomaly_detection.png`} alt="illustration" />
                </li>
                <li
                  onClick={() => scrollIntoViewWithOffset("CourseVibe")}
                >
                  <img src={`${process.env.PUBLIC_URL}/images/banner/banner-01/sentiment_analysis.png`} alt="illustration" />
                </li>
                <li
                  onClick={() => scrollIntoViewWithOffset("TestGenius")}
                >
                  <img src={`${process.env.PUBLIC_URL}/images/banner/banner-01/natural_language_processing.png`} alt="illustration" />
                </li>
                <li
                  onClick={() => scrollIntoViewWithOffset("HealthGuidePlus")}
                >
                  <img src={`${process.env.PUBLIC_URL}/images/banner/banner-01/knowledge_mining.png`} alt="illustration" />
                </li>
                <li
                  onClick={() => scrollIntoViewWithOffset("SDGNavigator")}
                >
                  <img src={`${process.env.PUBLIC_URL}/images/banner/banner-01/recommendation_systems.png`} alt="illustration" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="scroll-down-btn">
        <ScrollTo
          className="round-btn"
          to="introduction"
          spy={true}
          smooth={true}
          duration={200}
        >
          <i className="icon-arrow-down-s-line"></i>
        </ScrollTo>
      </div>      
    </div>
  </>
  )
}

export default AppliedAISolutionsBanner;