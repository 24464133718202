import React from 'react';
import { Element } from 'react-scroll';
import SkillsboostSectionTitle from './SkillsboostSectionTitle';

const SkillsboostWhyChooseUs = ({
  setShowSkillsboostSignUpForm
}) => {
  return (
    <>
      <Element
        name="WhyChooseUs"
        className="about-style-1 data-talent-eligibility-section edu-section-gap home-one-cat"
      >
        <div className="container inner about-feature-list">
          <div className="row g-5">
            <div className="col-lg-12">
              <SkillsboostSectionTitle
                classes = "text-center"
                title = "Why Choose Us?"
              />
            </div>
            <div className="courses-description-content mt--40">
              <p>Unlock the future of technology with our comprehensive program designed to help participants gain industry-aligned certifications that are sought after in Data Science, ML, and AI.</p>
            </div>
            {/* <div>
              <h4 className="text-center">Funded By</h4>
              <div className="row align-items-center">
                <a className="d-flex justify-content-center col-lg-6" href="https://paletteskills.org/upskill-canada"><img src={`${process.env.PUBLIC_URL}/images/logo/upskill_canada_logo_v3.png`} width="75%" alt="logo" loading="lazy" /></a>
                <a className="d-flex justify-content-center col-lg-6 py-4" href="https://www.canada.ca/en.html"><img src={`${process.env.PUBLIC_URL}/images/logo/govt_of_canada.svg`} width="50%" alt="logo" loading="lazy" /></a>
              </div>
              <h4 className="text-center mt--40">In Partnership With</h4>
              <div className="row align-items-center">
                <a className="d-flex justify-content-center col-lg-6 py-3" href="https://www.riipen.com"><img src={`${process.env.PUBLIC_URL}/images/logo/Riipen_logo.svg`} width="50%" height="auto" alt="logo" loading="lazy" /></a>
                <a className="d-flex justify-content-center col-lg-6" href="https://hearherstories.com/diwo-lumen-datatalent"><img src={`${process.env.PUBLIC_URL}/images/logo/Diwo_logo_v2.png`} width="50%" alt="logo" loading="lazy" /></a>
              </div>
            </div> */}
            {/* <img src={`${process.env.PUBLIC_URL}/images/data-talent/Powered-by-all-partners.png`} alt="logos" /> */}
            <div className="our-feature mt--40">
              <div className="icon">
                <i className="icon-ribbon"></i>
              </div>
              <div className="feature-content">
                <h6 className="feature-title"><em>Unlock Data Science, AI Career Certifications</em></h6>
                <p className="feature-description">Comprehensive Courses: Access to video tutorials to help you prepare for the certification exam. Certification Cost Covered: We've got you covered! Upon successfully passing the exam and providing the invoice, we reimburse the certification cost of $100 USD.</p>
              </div>
            </div>
            <div className="our-feature mt--40">
              <div className="icon">
                <i className="icon-share-fill"></i>
              </div>
              <div className="feature-content">
                <h6 className="feature-title"><em>Cloud AI Resource Subscription</em></h6>
                <p className="feature-description"><strong>Hands-On Practice</strong>: Practice makes perfect! Benefit from a Cloud AI resource subscription for exercising your skills with practical exercises. We cover the cost, offering you a dedicated learning environment.<br></br>
                <strong>3-Month Access</strong>: Enjoy access to the resource subscription for a period of three months or upto 150 CAD (whichever comes first), allowing you to deepen your understanding and refine your skills.</p>
              </div>
            </div>
            <div className="our-feature mt--40">
              <div className="icon">
                <i className="icon-student-read"></i>
              </div>
              <div className="feature-content">
                <h6 className="feature-title"><em>Work Integrated Learning Opportunities</em></h6>
                <p className="feature-description"><strong>Continuous Support</strong>: Stay informed about relevant Work Integrated Learning (WIL) opportunities. We provide regular updates on paid opportunities for High School students, University students, Recent Grads, and Professionals.</p>
              </div>
            </div>
          </div>
            <div className="shape shape-6 about-parallax-2 d-xl-block d-none">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-07.png`} alt="Shape Thumb" loading="lazy" />
            </div>
        </div>
      </Element>
    </>
  )
}

export default SkillsboostWhyChooseUs;