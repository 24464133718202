import React from 'react';
import { Element } from 'react-scroll';
import AimindsSectionTitle from './AimindsSectionTitle';

const AimindsDescription = () => {
  return (
    <>
      <Element
        name="introduction"
        className="data-talent-eligibility-section service-wrapper-1 style-variation3 blog-main-content justify-content-center edu-section-gap"
      >
        <div className="container about-feature-list eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-lg-12">
              <AimindsSectionTitle
                classes = "text-center"
                title = "Overview"
              />
            </div>
            <blockquote className="skillsboost-description blockquote-style-1 mt--30">“ Our program is designed to foster collaboration in the fields of Data Science, Machine Learning, and AI. By participating in our Emerging Tech Collaborations, you can contribute as a mentor, share your insights through guest lectures, or both. ”
              <img className="quote-image" src="/images/icons/quote-2.png" alt="Quote Images" />
            </blockquote>
          </div>
          
          <div className="row g-5 mt--40">
            <div className="col-lg-12">
              <AimindsSectionTitle
                classes = "text-center"
                // slogan = "Eligibility"
                title = "How It Works?"
              />
            </div>
          </div>
          <div className="row mt--40">
            <div className="col-12 our-feature">
              <div className="icon">
                <i className="icon-file-text-line"></i>
              </div>
              <div className="feature-content">
                <h6 className="feature-title"><em>Registration</em></h6>
                <p className="feature-description">Aspiring mentors and guest lecturers can register through our online platform. Provide details about your specialization, industry experience, and preferences for mentorship or guest lectures. Doctoral credentials or those who have done relevant Industry certifications (e.g., Microsoft Azure AI, AWS, Google) or proven relevant Project experience will be given preference.</p>
              </div>
            </div>
            <div className="col-12 our-feature mt--20">
              <div className="icon">
                <i className="icon-share-fill"></i>
              </div>
              <div className="feature-content">
                <h6 className="feature-title"><em>Matching Process</em></h6>
                <p className="feature-description">We will make sure to connect Industry Professionals with individuals seeking guidance. Factors such as expertise, project experience, and career aspirations ensure meaningful pairings.</p>
              </div>
            </div>
            <div className="col-12 our-feature mt--20">
              <div className="icon">
                <i className="icon-presentation"></i>
              </div>
              <div className="feature-content">
                <h6 className="feature-title"><em>Orientation Session</em></h6>
                <p className="feature-description">Once matched, Industry Professionals participate in an orientation session to understand Program guidelines. Learn about the Program benefits, communication tools, and expectations specific to the emerging tech landscape.</p>
              </div>
            </div>
          </div>
          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-2">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-08.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-3">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-4">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-02.png`} alt="Shape Thumb" loading="lazy" />
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default AimindsDescription;