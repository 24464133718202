import React from 'react';
import { Element } from 'react-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';

const HomeOneOurApproach = () => {

  return( 
    <>
      <Element
        name="about"
        className="edu-about-area about-style-1 edu-section-gap service-wrapper-1 bg-white"
      >
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <SectionTitle
              slogan="How We Do It"
              title="Our Approach"
              classes="text-center"
            />
            <div className="col-12">
              <p className="about-m2m">At M2M Tech, we follow a collaborative approach to AI solution development, partnering with our clients to understand their unique challenges and objectives. By combining industry expertise with cutting-edge technologies, we deliver customized AI solutions that drive tangible results and exceed expectations.</p>
            </div>
          </div>
        
          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-02.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-2">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-08.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-3">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-4">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default HomeOneOurApproach;